import {
  get,
  post,
  put,
  del,
  getExel,
  getType,
  putById,
  getDrugExel,
} from "./base";

// 医学公式 -- 获取使用记录
export function getFormulaRecord(data){
    return get("/v3/yun/medicine/formula/log",data)
}
// 医学公式 --  使用记录导出
export function getFormulaRecordExport(data){
    return getExel("/v3/yun/medicine/formula/log/export",data)
}
// 单位换算
export function getUnitConversion(data){
    return get("/v3/yun/medicine/formula/until/conversion",data)
}
// 单位换算导出
export function getUnitConversionExport(data){
    return getExel("/v3/yun/medicine/formula/until/conversion/export",data)
}
// 医学公式 ---分类管理
//获取类别
export function getType1(pageNo, pageSize,data) {
  return get(`/v3/yun/medicine/category/${pageNo}/${pageSize}`,data);
}
// 添加类别
export function addType(data) {
    return post('/v3/yun/medicine/category',data)
}
//删除类别
export function delType(id) {
       return del(`/v3/yun/medicine/category/${id}`);
}

// 修改类别
export function editType(data) {
    return put('/v3/yun/medicine/category',data);
}

// 移动类别
export function moveType(type,id) {
      return put(`/v3/yun/medicine/category/move/${type}/${id}`);
}

// 获取类别
export function getMenuList(isIssure) {
        return get(`/v3/yun/medicine/formula/category/${isIssure}`);
}
// 医学公式 --- 纠错记录
//  获取
export function getError(data) {
       return get("/v3/yun/medicine/formula/errorRecord",data);
}
// 导出
export function getExcel(data) {
      return getDrugExel("/v3/yun/medicine/formula/errorRecord/export", data);
}
// 解决
export function setError(data) {
     return putById("/v3/yun/medicine/formula/errorRecord", data);
}
// 推荐
export function postRecommend(data) {
    return post("/v3/formula/recommend",data);
}
// 获取推荐列表  
export function getRecommend(data) {
    return get("/v3/formula/recommend",data);
}
// 上移下移
export function moveRecommendList(type, id) {
    return put(`/v3/formula/recommend/${type}/${id}`);
}
// 下架
export function delRecommendList(id) {
  return del(`/v3/formula/recommend/${id}`);
}